<template>
  <b-overlay :show="isCreatingResource">
    <template #overlay>
      <div class="text-center">
        <loading message="Importando pólizas..."></loading>
      </div>
    </template>

    <b-form @submit.prevent="submit" class="momentosContables__form">
      <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <b-form-group>
          <b-form-file v-model="files" :state="Boolean(files.length)" multiple plain></b-form-file>
        </b-form-group>
      </div>

      <div class="my-2 d-flex">
        <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>

        <b-button
          type="submit"
          variant="success"
          class="ml-auto"
          size="sm"
          :disabled="!isFormPolizaValid"
          @click="submit"
        >
          Subir
        </b-button>
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MomentosContablesForm',

  props: {
    dispatchPath: {
      type: String,
      default: 'polizasRHModule/importarPolizaXaml'
    },

    pathPayloadForDispatchAction: {
      type: String,
      default: 'polizasRHModule/getImportPoliza'
    }
  },

  created () {
    this.init()
  },

  computed: {
    ...mapGetters('polizasRHModule', ['isFormPolizaValid', 'isCreatingResource']),

    //
    // Guarda y obtiene la información de Vuex
    files: {
      get () {
        return this.$store.state.polizasRHModule.importPoliza.files
      },
      set (value) {
        this.$store.commit('polizasRHModule/setFilesToImportPoliza', value)
      }
    }
  },

  methods: {
    /**
     * Inicializa el componente para seleccionar el periodo fiscal actualmente activo
     */
    async init () {
      const activo = await this.$getPeriodoFiscalActivo()

      this.$store.commit('polizasRHModule/setPeriodoFiscalToImportPoliza', activo.id)
    },

    async submit () {
      const getval = await this.$store.dispatch(
        this.dispatchPath,
        this.$store.getters[this.pathPayloadForDispatchAction]
      )

      this.onSent()

      this.$notify(getval, 'Importación de pólizas')
    },

    clearForm () {
      this.$store.commit('polizasRHModule/setFilesToImportPoliza', [])
    },

    onSent () {
      this.$emit('on-sent')
    }
  }
}
</script>

<style lang="scss" scoped>
.momentosContables__form {
  max-width: 600px;
  margin: auto;
  min-height: 25vh;
  border: 3px dashed pink;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
